<template>
  <div>
    <p class="text-2xl mb-6">Consultas SQl</p>
    <v-card>
      <v-card-text>
        <v-textarea outlined label="Query Sql" v-model="querySql"></v-textarea>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-text class="text-right">
        <v-btn class="primary" :loading="consultando" @click="consultar()">Consultar</v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      
      <v-card-text v-if="(resultados.command && resultados.command == 'SELECT')">
        <p>Resultado</p>

        <div v-if="resultados.rowCount == 0">
          <p>No hubo resultados para esta consulta</p>
        </div>
        <div v-else>
          <v-text-field
            label="Nombre Reporte"
            v-model="NombreReporte"
            dense 
            outlined
          ></v-text-field>
          <TablaSimpleReporte
            :nombreReporte="NombreReporte"
            :cabeceraIn="cabecera"
            :datosIn="datos" 
            :exportar="['EXCEL', 'PDF']"
            :orientacionReporte="'l'"
          ></TablaSimpleReporte>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService.js'

import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
// demos

export default {
  components: {
    TablaSimpleReporte,
  },
  setup() {
    const querySql = ref('')
    const consultando = ref(false)
    const resultados = ref({})
    const cabecera = ref([])
    const datos = ref([])
    const NombreReporte = ref("Nombre Reporte")
    const consultar = () => {
      consultando.value = true
      MaestroService.consultaSql({ query_sql: querySql.value })
        .then(response => {
          resultados.value = response.data
          if (resultados.value.command == 'SELECT' &&  resultados.value.rowCount > 0 ) {
            cabecera.value = []

            resultados.value.fields.forEach(element => {
              cabecera.value.push( { text: element.name, value: element.name, format: { small: true } })
            });

            datos.value = resultados.value.rows
          }
          else if (resultados.value.command == 'UPDATE') {
            store.commit('setAlert', {
            message:   'Actualizacion realizada con exito',
            type: 'success',
          })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: 'Error ' + err,
            type: 'error',
            
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
              funcion: 'consultar-consultasql',
          })
        })
        .finally(() => {
          consultando.value = false
        })
    }

    return {
      querySql,
      consultar,
      consultando,
      resultados,
      cabecera,
      datos,
      NombreReporte
    }
  },
}
</script>
